const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#E64A92", hover: "#922572" },
    secondary: { main: "#E64A92", hover: "#922572" },
    accent: "#E64A92",
    info: {
      main: "#E64A92",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
