import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        "We are an independent, family run restaurant specialising in Indian and
        Bangladeshi cuisine that offers free home delivery.
      </Typography>

      <Typography variant="body1" paragraph>
        We offer a broad range of dishes from Tandoori Chicken to Achary Lamb as
        well as an assortment of seafood, vegetarian and green masala dishes. We
        offer everything you require for the perfect lunch or dinner.
      </Typography>

      <Typography variant="body1" paragraph>
        Our team is passionate about our food and our customers. We give it our
        all each and every day to provide the best service available in London"
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
